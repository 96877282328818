<template>
  <v-app app>
    <NotificationMessage />
    <Navbar v-if="displayNavbarComponent"/>

    <!--
      This code must be add here so that you can viusalize all the pages inside 
      the 'view' folder, otherwise, it will not appear

      - You can add margin into the v-content so that it will appear below the Navbar 
      Check this example with the margin and without
      <v-content>  // without the margin
      <v-content class="mx-4 mb">  // with the margin
        To play with the vuetify framework margins go to this link : https://vuetifyjs.com/en/styles/spacing/
    -->
    
    <v-content class="mx-4 mb">
      <router-view></router-view>
    </v-content>

    <Footer />

  </v-app>
</template>

<style>

</style>

<script>
import NotificationMessage from '@/components/NotificationMessage.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    NotificationMessage
},
  data() {
    return {
      //displayNavbarComponent: true,
      //

    }
  },// end of data()
  // mounted(){
  //   this.$store.dispatch("changeSnackbarNotificationDetails", "how are you", "success", "1000");
  //   this.$store.dispatch("changeSnackbarNotificationStatus", true);
    
  // },
  computed:{
    displayNavbarComponent(){
      return this.$store.state.showNavBar
    }

  }//end of computed
  

}

</script>


<!-- <style>
@font-face {
  font-family: 'rabikiso';
  src: url('~@/assets/fonts/Rubik_Iso/RubikIso-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Divani Mazar';
  src: url('~@/assets/fonts/diwaniFont/divani-mazar.ttf') format('truetype');
}
@font-face {
  font-family: 'numStandard';
  src: url('~@/assets/fonts/Old_Standard_TT/OldStandardTT-Bold.ttf') format('truetype');
}
</style> -->
