import Vue from "vue";
import Vuex from "vuex" // this is a plugin for vuejs
import VuexPersistence from 'vuex-persist'



/*
Vuex has similar instances as vuejs. For example, in vuejs we use data() and here in vuex we use 'state' to add variables, 
in vuejs we use methods() and here in vuex we use something called 'actions' for adding methods. Moreover, In vuejs we use 'computed'
to change the data in real time and here in vuex we use 'getters' to access the data available in 'state'. In addition, in vuex we 
have extra instance we call it 'mutations' which is the recommended and the proper way to change the data in the state. We can
change the data in the state directly, but its not prefearable. The main reason because the mutations can track the changes of the data
and this is very usefull for debugging
*/







/* 
The first code without using encryption
*/
const vuexLocal = new VuexPersistence({ // this class has the built in get/set functions to retrieve the data from the localstorage.
    storage: window.localStorage, 
  });


  //the second code by using encryption. Enable it to use it.

// import Crypto from 'crypto-js';
// import Cookie from 'js-cookie';
// //import uuid  from 'uuid';
// import {  v4 as uuidv4 } from 'uuid';

// const cookieName = 'cookieName';
// const storageKey = 'storageKey';
// // Get the encryption token from cookie or generate a new one.
// const encryptionToken = Cookie.get(cookieName) || uuidv4;
// // Store the encryption token in a secure cookie.
// Cookie.set(cookieName, encryptionToken, { secure: true, expires: 180 });

// const vuexLocal = new VuexPersistence({ 
/*    
this class has the built in get/set functions to retrieve the data from the localstorage.
but we modify it here so that we control the encryption and the decryption of the local storage.
This is the main functionality of this class:
    Generate a random UUID and store it in a secure cookie.
    Encrypt the store in the setItem setter, using the generated UUID.
    Decrypt the store in the getItem getter, using the generated UUID.
    Clear the store if the decryption fails in the getter.

check this website to get further information "https://sandulat.com/safely-persisting-vuex-store-in-local-storage/"
*/
//     storage: {
//       getItem: () => {
//         // Get the store from local storage.
//         const store = window.localStorage.getItem(storageKey);
  
//         if (store) {
//           try {
//             // Decrypt the store retrieved from local storage
//             // using our encryption token stored in cookies.
//             const bytes = Crypto.AES.decrypt(store, encryptionToken);
  
//             return JSON.parse(bytes.toString(Crypto.enc.Utf8));
//           } catch (e) {
//             // The store will be reset if decryption fails.
//             window.localStorage.removeItem(storageKey);
//           }
//         }
  
//         return null;
//       },
//       setItem: (key, value) => {
//         // Encrypt the store using our encryption token stored in cookies.
//         const store = Crypto.AES.encrypt(value, encryptionToken).toString();
  
//         // Save the encrypted store in local storage.
//         return window.localStorage.setItem(storageKey, store);
//       },
//       removeItem: () => window.localStorage.removeItem(storageKey),
//     },
//   });
  

Vue.use(Vuex);
//import VueCookies from 'vue-cookies'
export default new Vuex.Store({
    state: { // similar to data()
        // i'm accessing these data directlym but it's better to access them by getter
        showNavBar: false,

        snackbarSwitcher: false,
        snackbarNotificationMessage: [
            {
               Text: "page is refreshed", 
               Color: "error", 
               Timeout: 0,}
           ],
        categorySelected: 'All',
        
        
        //chat messages color
        userMessageColorStore: '',
        recieverMessageColorStore: '',



    },
    actions: {// similar to methods()

        // I didn't uncomment the code of the localstorage and other stuff to be empty because it may related to something else and you may need the access token 
        // to enter other pages
        logout(){ //
            //localStorage.clear();
            //VueCookies.remove("access_token"); // remove token of domain.com and *.doamin.com
            //VueCookies.remove("gitlab_access_token"); // remove token of domain.com and *.doamin.com

            this.state.showNavBar = false // this will disable the NavBar. It will disable it directly without the mutation
        },

        // FOR THE NAVIGATION BAR
        changeStateOfNavBar({commit}, trueOrFalse){
            commit('MUTATION_NAV_SWITCHER', trueOrFalse)
        },
        /////////   END OF THE NAVIGATION BAR
        

        // FOR THE SNACKBAR NOTIFICATION MESSAGES
        changeSnackbarNotificationStatus({commit}, trueOrFalse){
            commit('SNACKBAR_SWITCHER', trueOrFalse)
        },
        changeSnackbarNotificationDetails({commit}, text, color, timeout){
            commit('SNACKBAR_NOTIFICATION_MESSAGE', text, color, timeout)
        },
        ///////////  END OF SNACKBAR NOTIFICATION MESSAGES

        //change username
        changeUsername({commit}, Name){
            commit('CHANGE_USERNAME', Name)
        },

        //change chat messages color
        changeUserMessageColor({commit}, color){
            commit('CHANGE_USER_Message_COLOR', color)
        },
        changeRecieverMessageColor({commit}, color){
            commit('CHANGE_RECIEVER_MESSAGE_COLOR', color)
        },

        // category Selection

        changeCategorySelection({commit}, selectionName){
            commit('CHANGED_CATEGORY', selectionName)
        },


    },
    getters: {// similar to computed()

    },
    mutations:{// new feature to change the variables in state

        // FOR THE NAVIGATION BAR
        MUTATION_NAV_SWITCHER(state , trueOrFlase){
            state.showNavBar = trueOrFlase
        },
        /////////   END OF THE NAVIGATION BAR


        // FOR THE SNACKBAR NOTIFICATION MESSAGES
        SNACKBAR_SWITCHER(state , trueOrFlase){
            state.snackbarSwitcher = trueOrFlase
        },
        // SNACKBAR_NOTIFICATION_MESSAGE(state , text, color, timeout){
        //     state.snackbarNotificationMessage.Text = text,
        //     state.snackbarNotificationMessage.color = color,
        //     state.snackbarNotificationMessage.timeout = timeout

        // },
        // ///////////  END OF SNACKBAR NOTIFICATION MESSAGES

        SNACKBAR_NOTIFICATION_MESSAGE(state , messageDescription){
            //reset the notification first.
            state.snackbarSwitcher = false
            state.snackbarNotificationMessage.Timeout = 0
            state.snackbarSwitcher = true


            state.snackbarNotificationMessage.Text = messageDescription[0]['Text']
            state.snackbarNotificationMessage.Color = messageDescription[0]['Color']
            state.snackbarNotificationMessage.Timeout = messageDescription[0]['Timeout']

        },
        ///////////  END OF SNACKBAR NOTIFICATION MESSAGES

        CHANGE_USERNAME(state, selectionName){
            state.gitlabUsername = selectionName
        },

        // category Selection
        CHANGED_CATEGORY(state, name){
            state.categorySelected = name
        },


        // chat Messages color
        CHANGE_USER_Message_COLOR(state, color){
            this.state.userMessageColorStore = color

        },
        CHANGE_RECIEVER_MESSAGE_COLOR(state, color){
            this.state.recieverMessageColorStore = color
        },// end of the chat Messages color

        
    },
    plugins: [vuexLocal.plugin],
})