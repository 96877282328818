<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <nav >
     <!-- <v-app-bar app color="white" flat scroll-behavior="hide collapse elevate fade-image" src="https://picsum.photos/1920/1080?random" scroll-threshold="1000"></v-app-bar> -->
     <v-app-bar app color="white">
       
       <v-badge color="#D5F0DB" dot>
         <v-img src="/images.jpeg" contain width="30"></v-img>
       </v-badge>
       
       <v-toolbar-title class="ml-4">
         <span class="green--text">AlAwqaf</span><strong>AlJafaria</strong>
       </v-toolbar-title>
   
       <v-spacer></v-spacer>
       
       
       
       <v-btn text color="grey" @click="signout">
        <span>Sign Out</span>
        <v-icon right></v-icon>
      </v-btn>
 
 
       <!-- <v-badge color="#FF6D59" overlap content="2" class="mr-2 mt-1">
         <v-avatar color="#FFF0EE" size="40">
           <v-icon x-small color="#FF6D59">far fa-heart</v-icon>
         </v-avatar>
       </v-badge>
       <v-badge color="#41AB55" overlap content="3" class="mr-2 mt-1">
         <v-avatar color="#ECF7EE" size="40">
           <v-icon x-small color="#41AB55">fas fa-shopping-cart</v-icon>
         </v-avatar>
       </v-badge> -->
       
       <span class="grey--text d-none d-sm-flex">&nbsp;<strong>Admin</strong>&nbsp; ، مرحبا </span
       >
       
 
 
 
         <v-menu offset-y>
             <template v-slot:activator="{ on, attrs }">
                 <v-btn text color="grey" v-bind="attrs" v-on="on">
 
       <v-avatar size="30" class="ml-2">
         <!-- <v-img src="https://cdn.vuetifyjs.com/images/lists/2.jpg"></v-img> -->
         <v-img src="/images.jpeg"></v-img>
       </v-avatar>
     </v-btn>
             </template>
             <v-list>
                 <v-list-item v-for="link in profile_links" :key="link.text" router :to="link.route">
                     <v-list-item-title>{{ link.text }}</v-list-item-title>
                 </v-list-item>
             </v-list>
         </v-menu>
 
 
       
       <v-app-bar-nav-icon  class="grey--text" @click="isDrawerEnabled = !isDrawerEnabled"></v-app-bar-nav-icon> <!-- use 'v-app-bar-nav-icon' instade of 'v-toolbar-side-icon'  -->
 
 
 

       
     <v-navigation-drawer
   
   v-model= "isDrawerEnabled"
   right 
   absolute
   rounded
   dark
   app
   src="/navbar.png"
   temporary>
   <!-- 
   src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"    the src was from the url
   It's not recommended to use the url because whenever you dont have internet connection, it will not appear.
   --> 
   <v-layout d-flex align-space-around justify-center column class="items pa-5">
   <v-list dense>
       <v-list-item v-for="link in navbar_links" :key="link.text" router :to="link.route">
       <v-list-item-icon>
           <v-icon class="white--text">{{ link.icon }}</v-icon>
       </v-list-item-icon>
 
       <v-list-item-content>
           <v-list-item-title class="white--text">{{ link.text }}</v-list-item-title>
       </v-list-item-content>
       
       </v-list-item>
   </v-list>
   </v-layout>
   </v-navigation-drawer>
 
   
 
     </v-app-bar>
 
 
 
 
 
 
 
   </nav>
   </template>

<script>
import VueCookies from 'vue-cookies'

export default{
    components: {
},
    data(){
        return{
          showNav: false,
            isDrawerEnabled: false,

            profile_links: [
                {icon: 'mdi-home-city', text: 'Home', route: '/'},
                {icon: 'mdi-home-city', text: 'Logout', route: '/Dashboard'},
            ],
            navbar_links: [
                {icon: 'mdi-home-city', text: 'home', route: '/'},
                // {icon: 'mdi-home-city', text: 'Chatbot', route: '/'},
                // {icon: 'mdi-home-city', text: 'Saved Messages', route: '/'},
            ]
    }
    },
    methods:{
        signout(){
            this.$store.dispatch("logoutFromGitlab")

            localStorage.clear();
            VueCookies.remove("access_token"); // remove gitlab token of domain.com and *.doamin.com

            this.$store.dispatch("changeStateOfNavBar", false) // this will disable the NavBar

            // it will redirect the client to the gitlablogin page
            this.$router.push({ path: "/login" });
          
        }
    }
}
</script>


<style>
.v-toolbar__title {
  font-size: 1rem !important;
}
.items {
  max-height: 200px;
  overflow: auto;
}

</style>