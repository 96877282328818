<!-- This was the old version of the notification, and all the values of the notification was retrived from the 'data()'  
These are the values that are retrived from...:
      data(){
          return {
            snackbarSwitcher: true,
            snackbarNotificationMessage: [
                { Text: ""},
                { color: "success"},
                { timeout: "10000"},
            ],
        }
      },
      but the probelm was you have to define the notification HTML script and the data() variables of the notification in each 'vue' filem which is not practicle
      you can acess the data from the vue file by
              // this.snackbarNotificationMessage.Text = "This scheduled pipeline has not been deleted sucessfully due to the following error: "+ response.message;
              // this.snackbarNotificationMessage.color = "error"
              // this.snackbarNotificationMessage.timeout = "8000"
              // this.snackbarSwitcher= true;
-->
<!-- <v-snackbar  shaped top v-model="snackbarSwitcher" :multi-line="true" :timeout=snackbarNotificationMessage.timeout :color=snackbarNotificationMessage.color>
    {{ snackbarNotificationMessage.Text }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="snackbarSwitcher = false, snackbarNotificationMessage='' ">
        Close
      </v-btn>
    </template>
  </v-snackbar> -->


<template>
<v-snackbar  shaped top v-model="displaySnackbarComponent" :multi-line="true" :timeout=snackbarDetails.Timeout :color="snackbarDetails.Color">
    {{ snackbarDetails.Text }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="closeSnackbarNotificaitonClicked">  <!-- This will change the notificationbar to false and will reset the text and the timout values-->
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>


<script>
//import format from 'data-fns/format' // you can format the data by this. It's better than the regular format. INSTALL IT FIRST
  export default{
      //name: 'ABout', // must be with two capital letters
      data(){
          return {
            // snackbarSwitcher: true,
            // snackbarNotificationMessage: [
            //     { Text: ""},
            //     { Color: ""},
            //     { Timeout: ""},
            // ],
        }
      },
      beforeMount(){
        /* this is very imported, see the problemsAndFixing.txt for more details
        */
        this.$store.dispatch('changeSnackbarNotificationStatus', false);
      },
      methods: {
        closeSnackbarNotificaitonClicked(){ // this is never being used, you can call it from the above html when you click on the close 'Notification button'
            this.$store.dispatch('changeSnackbarNotificationStatus', false)
            //var messageDetails = [{Text: '',Color:'',Timeout: null }]
            //this.$store.dispatch('changeSnackbarNotificationDetails', messageDetails); 
            
          }
      },
      computed: {
        displaySnackbarComponent: { 
            get() {
                return this.$store.state.snackbarSwitcher
            },
            set(v) {
                this.$store.dispatch('changeSnackbarNotificationStatus', v) // this is necessarily because the snackbar will control this to disable the notification, otherwise you will get an error in the web developer tool
                //var messageDetails = [{Text: '',Color:'success',Timeout: null }]
                //this.$store.dispatch('changeSnackbarNotificationDetails', messageDetails); 
            },
        },

        snackbarDetails(){ // retrive the notification values from the store
            return this.$store.state.snackbarNotificationMessage
    }
      }
  
  }
  </script>