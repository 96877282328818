// import HomeView from '../views/HomeView.vue'
/////////
//import { api } from "../settings/config"; // to get the 'API_URL' I didn't set it to global variable

import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import store from "@/VuexStore/Storage.js"

Vue.use(VueRouter)





const checkIfUserAlreadyLoggedInAndHasToken = function(to, from, next) {
  let access_token =  VueCookies.get("access_token");
  if (access_token == null) { // by using only this code you'll not be able to enter this route if there is no token
    // you can add a code here to clear the cookies and the local storage
    next();
    return;
  }
  else{
    router.push({ // if token is not null and you're trying to enter the login page, it will redirect to to the home page. You won't be able to access the login page if the clinet have the token in the browser
      name: 'homeG',
      params: {
        returnTo: to.path,
        query: to.query,
      },
    });
    return
  }
};// end checkIfUserAlreadyLoggedInIntoGitlabAndHasToken()


const isTokenValid = function(to, from, next) {
  // let access_token =  VueCookies.get("access_token");
// api.post("/checkIfTokenIsValid", {access_token})
// .then(response => {
//   if (response.status === 200) { 
//     // valid token
//     next();
//   }
//   else{
//     console.warn("invalid token");
//     // go to login page...................
//   }
//   }); // end then.
  
let access_token =  VueCookies.get("access_token");
if (access_token != null) { // by using only this code you'll not be able to enter this route
  next();
  return;
}
// if the access token isn't available, then the the navbar will disapear
store.dispatch("logout") 
// then it will redirect you to 
router.push({ // this code will redirect you to the login page if there is no access token
  name: 'login',
  params: {
    returnTo: to.path,
    query: to.query,
  },
});

};// end isTokenValid()






const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/Login.vue'),
    meta: { title: 'مجلس ادارة الاوقاف الجعفرية في دبي' }, // <- web page tab title
    beforeEnter: (to, from, next) => {
      checkIfUserAlreadyLoggedInAndHasToken(to, from, next);
      document.title = to.meta.title;
    
    },



    // beforeEnter: (to, from, next) => {isTokenValid(to, from, next);}
    //beforeEnter: isTokenValid,
  },
  {
    path: '/login',
    name: 'login',
    // component: Dashboard  // if you want to use it, just import the component first, but the below code is more efficient and easy to use
    component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/Login.vue'),
    meta: { title: 'مجلس ادارة الاوقاف الجعفرية في دبي' }, // <- web page tab title

    beforeEnter: (to, from, next) => {
      checkIfUserAlreadyLoggedInAndHasToken(to, from, next);
      document.title = to.meta.title;
    }

  },


  



/*
GITLAB ******************************************************************************************
******************************************************************************************
******************************************************************************************
******************************************************************************************
******************************************************************************************
******************************************************************************************
******************************************************************************************
Notes: if you get a problem when you add a new .vue file, this is probably because you 
didn't wrote the right path of the api. If you're inside a subdirectory, then you have to link the
path of the api correctly as showing below
this path will not work: import { gitlab_api_withHeader } from "../settings/config"; 
 this path will work   : import { gitlab_api_withHeader } from "@/settings/config";
*/

{
  path: '/homeg',
  name: 'homeG',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/Home.vue'),

  meta: { title: 'الرئيسية' }, // <- web page tab title
  // beforeEnter: (to, from, next) => {
  //   document.title = to.meta.title;
  //   next()
  // }

  beforeEnter: (to, from, next) => { 
    isTokenValid(to, from, next); 
    document.title = to.meta.title;
  
  }
  //beforeEnter: isTokenValid,
  
},
{
  path: '/listLang',
  name: 'listLang',
  // component: HomeView,  // if you want to use it, import it first as shown above
  component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/List_Ai_lang.vue'),
  meta: { title: 'مجلس ادارة الاوقاف الجعفرية في دبي' }, // <- web page tab title

  beforeEnter: (to, from, next) => { 
    isTokenValid(to, from, next); 
    document.title = to.meta.title;
  }
  //beforeEnter: isTokenValid,

},

{
  path: '/listLang/:langFolder',
  name: 'langCat',
  // component: Dashboard  // if you want to use it, import it first as shown above
  component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/List_lang_cat.vue'),
  meta: { title: 'مجلس ادارة الاوقاف الجعفرية في دبي' }, // <- web page tab title

  beforeEnter: (to, from, next) => {
    isTokenValid(to, from, next);
    document.title = to.meta.title;
  }
  //beforeEnter: isTokenValid,

},

{
  path: '/listLang/:langFolder/:langCatFolder/listTags',
  name: 'dash',
  // component: HomeView,  // if you want to use it, import it first as shown above
  component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/List_lang_cat_tags.vue'),
  meta: { title: 'مجلس ادارة الاوقاف الجعفرية في دبي' }, // <- web page tab title
  beforeEnter: (to, from, next) => {
    isTokenValid(to, from, next);
    document.title = to.meta.title;
  }

},

{
  path: '/listLang/:langFolder/:langCatFolder/AddEditTag',
  name: 'dash',
  // component: HomeView,  // if you want to use it, import it first as shown above
  component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/AddEditTagNew.vue'),
  meta: { title: 'مجلس ادارة الاوقاف الجعفرية في دبي' }, // <- web page tab title
  beforeEnter: (to, from, next) => {
    isTokenValid(to, from, next);
    document.title = to.meta.title;
  }

},

{
  path: '/monitoring/:langFolder/:langCatFolder',
  name: 'monitoring',
  // component: HomeView,  // if you want to use it, import it first as shown above
  component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/MonitoringUserInput.vue'),
  meta: { title: 'مجلس ادارة الاوقاف الجعفرية في دبي' }, // <- web page tab title
  beforeEnter: (to, from, next) => {
    isTokenValid(to, from, next);
    document.title = to.meta.title;
  }

},

{
  path: '/chatpdfdata/:langFolder/:langCatFolder',
  name: 'monitoring',
  // component: HomeView,  // if you want to use it, import it first as shown above
  component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/Chatpdf_Data.vue'),
  meta: { title: 'مجلس ادارة الاوقاف الجعفرية في دبي' }, // <- web page tab title
  beforeEnter: (to, from, next) => {
    isTokenValid(to, from, next);
    document.title = to.meta.title;
  }

},

{
  path: '/chatbot/:langFolder/:langCatFolder',
  name: 'chatbot',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/Chatbot.vue'),
  
  meta: { title: 'الذكاء الاصطناعي' }, // <- web page tab title
  beforeEnter: (to, from, next) => {
    isTokenValid(to, from, next);
    //beforeEnter: isTokenValid,
    document.title = to.meta.title;
    next()
  }
  
},



  // {
  //   path: '/ListProjects',
  //   name: 'ListProjects',
  //   // component: Dashboard  // if you want to use it, just import the component first, but the below code is more efficient and easy to use
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/ListProjects.vue'),
  //   meta: { title: 'Projects' },  // this is the title of the page


  //   beforeEnter: (to, from, next) => {isGitlabTokenValid(to, from, next);}
  //   //beforeEnter: isTokenValid,

  // },
  // {
  //   path: '/ListBranches/:branchName/:id', // you can use this path "/ListBranches/:id" to route to this page
  //   name: 'ListBranches',  // you can use this name to route to this page
  //   // component: Dashboard  // if you want to use it, just import the component first, but the below code is more efficient and easy to use
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/ListBranches.vue'),
  //   meta: { title: 'Branches' }, // <- I would to use this one


  //   beforeEnter: (to, from, next) => {isGitlabTokenValid(to, from, next);}
  //   //beforeEnter: isTokenValid,

  // },
  // {
  //   path: '/ProjectUsers/:branchName/:id', // you can use this path "/ListBranches/:id" to route to this page
  //   name: 'ProjectUsers',  // you can use this name to route to this page
  //   // component: Dashboard  // if you want to use it, import it first as shown above
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/ProjectUsers.vue'),
  //   meta: { title: 'Project Users' }, // <- I would to use this one


  //   beforeEnter: (to, from, next) => {isGitlabTokenValid(to, from, next);}
  //   //beforeEnter: isTokenValid,

  // },
  // {
  //   path: '/Calendar/:branchName/:id', // you can use this path "/ListBranches/:id" to route to this page
  //   name: 'Calendar',  // you can use this name to route to this page
  //   // component: Dashboard  // if you want to use it, import it first as shown above
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard/Calendar.vue'),
  //   meta: { title: 'Project Users' }, // <- I would to use this one


  //   beforeEnter: (to, from, next) => {isGitlabTokenValid(to, from, next);}
  //   //beforeEnter: isTokenValid,

  // },
  {
    path: '/:catchAll(.*)*',
    component: () => import(/* webpackChunkName: "about" */ '../views/InvalidPage.vue'),
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
