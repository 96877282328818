import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);  // orignial 

// Modified
import colors from 'vuetify/lib/util/colors' // added for the dark mode
Vue.use(Vuetify, {
    iconfont: 'md',
    theme: {
        primary: '#9652ff',
        success: '#3cd1c2',
        info: '#ffaa2c',
        error: "#f83e70",
        // a: "#f83e70"   not working ..... same as quasar framework 
    },
    dark: {// addded for the dark mode
        primary: colors.blue.lighten3,
      },
})

export default new Vuetify({ // this will allow it be public in all the vue files
});

// you can use this as well to export the variables 'export default vuetify'.  Then you can access it from differnet vue file using 'this.$vuetify.theme.themes.light' 

